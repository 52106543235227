<template>
  <div class="box">
    <div class="header">
      <div class="whhh">
        <img
          class="headerimg"
          src="../../assets/yingyong anli_banner@2x.png"
          alt=""
        />
      </div>
      <div style="height: 530px"></div>
    </div>
    <div class="mk">
      <div class="w10">
        <div class="tablist">
          <div class="item active">案例一览</div>
        </div>
        <div class="contentlist" v-if="list.length > 0">
          <div
            class="items"
            @click="go"
            v-for="(item, index) in list"
            :key="index"
          >
            <img
              :src="item.cover || '../../assets/shouye_banner1.jpg'"
              alt=""
            />
            <div style="margin-left: 34px">
              <div class="item_1">
                {{ item.title }}
              </div>
              <div class="item_2">
                {{ item.profiles }}
              </div>
              <div class="item_3">{{ item.time }}</div>
            </div>
          </div>
        </div>
        <div v-else class="tac">—— 暂无案例 ——</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      list: [],
    };
  },
  methods: {
    ...mapActions(["getNews"]),
    go() {
      this.$router.push("/informationdetail");
    },
  },
  mounted() {
    this.getNews({
      classify_ids: 5,
    }).thne((res) => {
      if (res.successCode == 200) {
        this.list = res.responseBody;
      }
    });
  },
};
</script>

<style lang='scss' scoped>
.box {
  overflow: hidden;
  position: relative;
}
.headerimg {
  position: absolute;
  left: 50%;
  height: 530px;
  width: 1920px;
  transform: translateX(-50%);
  vertical-align: bottom;
}
.tablist {
  margin: 110px 0 50px 0;
  display: flex;
  justify-content: space-around;
  line-height: 80px;
  width: 1000px;
  height: 80px;
  background: #ffffff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  .item {
    position: relative;
  }
  .active {
    &::before {
      position: absolute;
      content: "";
      width: 57px;
      height: 4px;
      background: #1276fb;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}
.mk {
  overflow: hidden;
  background: url("../../assets/beijingtu.png");
}
.contentlist {
  .items {
    cursor: pointer;
    position: relative;
    padding: 24px 27px;
    box-sizing: border-box;
    display: flex;
    margin-bottom: 55px;
    width: 1000px;
    height: 211px;
    background: #ffffff;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    img {
      width: 242px;
      height: 165px;
      background: #ececec;
      border-radius: 10px;
    }
    .item_1 {
      margin-bottom: 15px;
      font-size: 18px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #222222;
    }
    .item_2 {
      font-size: 14px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #a5a5a5;
    }
    .item_3 {
      position: absolute;
      left: 303px;
      top: 165px;
      font-size: 14px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #a5a5a5;
    }
  }
}
.tac {
  text-align: center;
  margin: 200px 0;
}
</style>